/*

Author: Bootstrapthemes
Author URI: http://bootstrapthemes.co

*/
/* ==========================================================================
   Author's custom styles
   ========================================================================== */
html,
body {
  background-color: #1e2e3b;
  font-size: 15px;
  color: #565656;
  width: 100%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  font-family: 'roboto', sans-serif;
  font-weight: 300;
}

/*==========================================
   PRE LOADER 
   ==========================================*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fefefe;
  z-index: 99999;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
}

.loaded {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url(../../../public/assets/images/preloading.gif);
  background-repeat: no-repeat;
  background-position: center;
  -moz-background-size: cover;
  background-size: cover;
  margin: -20px 0 0 -20px;
}
img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  /* user-drag: none; */
}
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}
a,
button,
a span {
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.btn.extra-width {
  padding: 13px 40px;
}
.btn:focus,
.btn:active {
  outline: inherit;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /*    direction: rtl;*/
}
p {
  margin: 0 0 15px;
}
.clear {
  clear: both;
}
ol,
ul {
  list-style: none;
  padding: 0;
}
img {
  max-width: 100%;
}
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  outline: none;
}
.form-control {
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 16px;
  height: 34px;
  max-width: 90%;
  background: #323232;
}
.form-control:focus {
  border-color: #d6962c;
}
h1,
h2,
h3 {
  font-family: 'roboto', sans-serif;
  font-weight: 300;
  margin: 0;
}
h4,
h5 {
  font-weight: 300;
  margin: 0;
  line-height: 2rem;
  font-family: 'Pacifico', cursive;
}
h1 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 300;
}
h2 {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 0.9rem;
  text-transform: uppercase;
  color: #3879d9;
}
h3 {
  font-weight: 300;
  margin-bottom: 0.9rem;
  font-size: 2.375rem;
  line-height: 2.5rem;
}
h4 {
  font-size: 1.875rem;
  line-height: 2.2rem;
  margin-bottom: 1.1rem;
  font-family: 'Pacifico', cursive;
}
h5 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
h6 {
  font-size: 1rem;
  margin-bottom: 0.9rem;
}
p {
  line-height: 1.5rem;
  font-size: 1.1rem;
}
p:last-child {
  margin-bottom: 0px;
}

/*.home-wrap h1,*/
.home-wrap h2,
.home-wrap h3,
.home-wrap h4,
.home-wrap h5,
.home-wrap p {
  color: #ffffff;
  font-family: 'roboto', sans-serif;
}
.text {
  color: #333333;
}
.colorstext {
  color: #f56363;
}
.coloricon i {
  color: #f56363;
}
.colorsbg {
  background: #f56363;
  color: #ffffff;
}
.colorsbg .separator2 {
  border: 2px solid;
}
.colorsbg .separator_wrap::after,
.colorsbg .separator_wrap::before {
  background-color: #ffffff;
}
.lightbg {
  background-color: #f9f9f9;
}
.transition {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
/*
   Section
   */
section {
  margin: 0;
}
.sections {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
}
.sections.footer {
  padding-bottom: 80px;
}
section .subtitle h5 {
  margin-top: 10px;
  font-size: 1.3rem;
  font-family: 'roboto', sans-serif;
}
.parallax-section {
  max-width: 100%;
  color: #ffffff;
}
section .title-half h2 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 300;
  margin-bottom: 1.4rem;
}
section .subtitle-half h5 {
  font-weight: 100;
  font-size: 17px;
}
/*
   section:nth-of-type(odd) {
       background-color: #F9F9F9;
   }*/
.fw-special-title-half {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 100;
  /*    margin-bottom: 10px;*/
}
.heading {
  margin-bottom: 4rem;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  min-height: 750px;
}
.overlay-fluid-block {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}
.home-overlay-fluid {
  background-color: rgba(41, 41, 41, 0.68);
  width: 80%;
  margin: auto;
}
.overlay-img {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #ffffff;
}
.no-padding {
  padding: 0 !important;
}
.fluid-blocks-col {
  padding: 70px 40px 0 80px;
}
.fluid-blocks-col-right {
  padding: 70px 80px 0 40px;
}
/*
   Separator
   */
.separator {
  height: 1px;
  width: 150px;
  margin: auto;
  background: #f56363;
}
.separator-left {
  height: 1px;
  width: 150px;
  background: #f56363;
}
.separator_wrap {
  text-align: center;
}
.separator_wrap-left {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 30px;
}
.separator_wrap::after,
.separator_wrap::before {
  background-color: #f56363;
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 90px;
  height: 1px;
  margin-top: 2px;
}
.separator_wrap-left::after {
  background-color: #f56363;
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 80px;
  height: 1px;
  margin-top: 2px;
}
.separator2 {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 2px solid #f56363;
  transform: rotate(45deg);
  margin-right: 4px;
}
/*
   Button
   */
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary.disabled:hover {
  color: #ffffff;
  background-color: #d6962c;
  border-color: #d6962c;
}
.btn {
  margin-right: 15px;
  border-radius: 0px;
}
/*.btn-default:active, .btn-default:hover  {
       background: transparent;
   }*/
.btn-lg,
.btn-group-lg > .btn {
  padding: 8px;
  font-size: 1.2rem;
  border-radius: 4px;
  width: 200px;
  border: 1px solid #fff;
  text-transform: none;
  transition: 0.6s;
  margin-top: 30px;
  color: #fff;
}
button.btn-lg {
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 4px;
  width: 200px;
  border: 1px solid #fff;
  text-transform: none;
  transition: 0.6s;
  margin-top: 30px;
  color: #fff;
  display: inline-block;
  background: transparent;
  line-height: 1.5em;
}

/* } */
button.btn-lg:hover {
  background: #d6962c;
  color: #fff;
}

.btn-md,
.btn-group-md > .btn {
  padding: 8px 20px;
  font-size: 1.2rem;
  border-radius: 30px;
}

.btn-primary {
  color: #ffffff;
}
.btn-primary:active,
.btn-primary:hover {
  background: #d6962c;
}

.btn-default {
  color: #ffffff;
  border: 2px solid #ffffff;
  background-color: transparent;
}
.btn-default:before,
.btn-default:after {
  background-color: #ffffff;
}
.btn-default:focus {
  background-color: #ffffff;
  border-color: transparent;
}
.btn-default:hover {
  border-color: #ffffff;
  color: #f56363;
  background-color: #ffffff;
}
.btn-primary {
  background-color: #e17147;
  color: #ffffff;
  border: 2px solid #e17147;
  text-transform: uppercase;
  border-radius: 4px;
}
.btn-primary:before,
.btn-primary:after {
  background-color: #ffffff;
}
.btn-primary:focus {
  background-color: #d6962c;
  border-color: #d6962c;
}
.btn-primary:hover {
  border-color: #d6962c;
  color: #fff;
}
/*
   Extra height css
   */
.padding0 {
  padding: 0;
}
.center-content {
  text-align: center !important;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.padding-top-20 {
  padding-top: 1.33rem;
}
.padding-bottom-20 {
  padding-bottom: 1.33rem;
}
.padding-top-40 {
  padding-top: 2.66rem;
}
.padding-bottom-40 {
  padding-bottom: 2.66rem;
}
.padding-top-60 {
  padding-top: 5rem;
}
.padding-bottom-60 {
  padding-bottom: 5rem;
}
.padding-bottom-0 {
  padding-bottom: 0;
}
/*padding*/
.padding-twenty {
  padding: 10px 0;
}
.padding-fourty {
  padding: 20px 0;
}
.padding-sixty {
  padding: 30px 0;
}
.padding-eighty {
  padding: 40px 0;
}
h1 span {
  color: #f56363;
  font-weight: 400;
}

/*for placeholder changes*/

/*input::-webkit-input-placeholder {
     color: #CACACA;
     font-size: 18px;
   }
   input::-moz-placeholder {
     color: #CACACA;
     font-size: 18px;
   }
   input:-ms-input-placeholder {
     color: #CACACA;
     font-size: 18px;
   }*/

/* Header top menu css */

.header_top_menu {
  background: #fff;
  position: relative;
}

a.navbar-brand.our_logo {
  position: absolute;
  left: 0;
  top: -50px;
  width: 25%;
}

.header_top_menu .call_us_text a {
  color: #666;
  font-size: 15px;
  margin-left: 15px;
  padding: 5px;
  display: inline-block;
}
.header_top_menu .call_us_text a i {
  color: #e17147;
  margin-right: 10px;
  font-size: 18px;
}

.head_top_social a {
  color: #e17147;
  padding: 5px;
  display: inline-block;
}

/* main menu */
.menu-scroll {
  padding-top: 0px;
  padding-bottom: 0px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-color: #000;
  height: 80px;
}

.menu-scroll .header_top_menu {
  display: none;
}
.menu-scroll a.navbar-brand.our_logo {
  top: -16px;
}

.main_menu_bg {
  background: rgba(0, 0, 0, 0.45);
  height: 80px;
}
.navbar-default {
  background-color: transparent;
  border-color: transparent;
}
.container-fluid {
  margin-top: 15px;
}
.main_menu_bg li a.booking {
  border: 1px solid #fff;
  border-radius: 4px;
  color: #e17147 !important;
  padding: 5px 10px;
  margin-top: 8px;
}
.main_menu_bg li a:hover.booking {
  color: #da9b31 !important;
}

.main_menu_bg .navbar {
  margin-bottom: 0px;
}

.navbar-default .navbar-nav > li > a {
  color: #fff;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #da9b31;
}

/* slider section */

.slider {
  background: url(../../../public/assets/images/bannerbg.jpg) center center
    no-repeat;
  background-size: cover;
}
/* .main_slider {
     
   } */
.main_slider .main_slider_content h1 {
  font-size: 6rem;
  font-family: 'Pacifico', cursive;
  color: #fff;
  line-height: 6rem;
  margin: 0px 0px 60px 0px;
}
.main_slider_content {
  padding: 250px 0px 200px 0px;
  width: 70%;
  margin: 0 auto;
  color: #fff;
}

.slider_overlay {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  top: 0;
  left: 0;
}

/* Abouts Section css */

.abouts {
  background: url(../../../public/assets/images/abtbg.jpg) no-repeat center top
    scroll;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}
.abouts_content {
  padding: 120px 0px;
  display: inline-block;
}
.single_abouts_text h4 {
  color: #000000;
}
.single_abouts_text h3 {
  color: #000000;
}

/* Features section css */
.features {
  background: url(../../../public/assets/images/ftbg.jpg) center center
    no-repeat;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}
.main_features_content_area {
  padding: 120px 0px;
  display: inline-block;
}
.main_features_content {
  background: url(../../../public/assets/images/ft.png) right center no-repeat;
  padding: 40px;
  overflow: hidden;
}

.single_features_text {
  background: url(../../../public/assets/images/textbg.jpg) center center
    no-repeat;
  padding: 30px;
  background-size: cover;
}

/* Portfolio Section css */
.portfolio {
  background: url(../../../public/assets/images/portfoliobg.jpg) center top
    no-repeat;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}

.portfolio_content {
  padding-bottom: 120px;
  display: inline-block;
}
.portfolio .portfolio_content .head_title h3 {
  color: #000000;
}
.portfolio .portfolio_content .head_title h4 {
  color: #000000;
}

.head_title {
  padding-top: 100px;
  padding-bottom: 50px;
}

.single_portfolio_text {
  display: inline-block;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.single_portfolio_text img {
  width: 100%;
}

.single_portfolio_text:hover .portfolio_images_overlay {
  top: 28px;
  left: 14px;
}

.portfolio_images_overlay {
  width: 90%;
  height: 80%;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin: 0 auto;
  top: -100%;
  left: 14px;
  position: absolute;
  transition: 0.6s;
}
.portfolio_images_overlay h6 {
  text-transform: uppercase;
  color: #fff;
  font-size: 1.3125rem;
  line-height: 2rem;
}

.portfolio_images_overlay p.product_price {
  font-size: 2.5725rem;
  color: #fff;
  line-height: 3rem;
}

/*Amazing Deliceas Section css */
.ourPakeg .main_pakeg_content .head_title h3 {
  color: #fff;
}
.ourPakeg .main_pakeg_content .head_title h4 {
  color: #fff;
  font-family: 'Pacifico', cursive;
  font-size: 1.875rem;
}

.ourPakeg {
  background: url(../../../public/assets/images/newsbg.jpg) no-repeat center top
    scroll;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  padding-bottom: 120px;
}

.single_pakeg_one {
  background: url(../../../public/assets/images/newsone.jpg) left center
    no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}
.single_pakeg_two {
  background: url(../../../public/assets/images/newstwo.jpg) right center
    no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}
.single_pakeg_three {
  background: url(../../../public/assets/images/newsthree.jpg) left center
    no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}

.single_pakeg_text {
  background: #fff;
  padding: 30px;
  text-align: left;
}

.single_pakeg_text ul li {
  background: url(../../../public/assets/images/pkli.png) no-repeat left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

/* Mobail Apps Section css */

.mobailapps {
  background: url(../../../public/assets/images/appsbg.jpg) top center no-repeat
    scroll;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.main_mobail_apps_content {
  margin: 80px 0px;
  color: #fff;
  overflow: hidden;
}

.single_monail_apps_text {
  margin-top: 120px;
}
.single_monail_apps_text h4 {
  font-size: 1.875rem;
  margin-bottom: 30px;
}

.single_monail_apps_text h1 {
  font-size: 5rem;
  text-transform: uppercase;
  color: #fff;
  line-height: 5rem;
}
.single_monail_apps_text h1 span {
  font-size: 1.8rem;
  color: #fff;
  display: block;
}

.single_monail_apps_text {
  margin-left: -10px;
}
.single_monail_apps_text a {
  margin-top: 50px;
  margin-left: 10px;
  display: inline-block;
}
.single_monail_apps_text a img {
  max-width: 90%;
}

/* Footer Section CSS */
.footer_widget {
  background: url(../../../public/assets/images/footerbg.jpg) center top
    no-repeat scroll;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}
.footer_widget_content {
  color: #fff;
}
.single_widget {
  padding-bottom: 50px;
  padding-top: 60px;
}

.single_widget h3 {
  font-size: 1.875rem;

  font-family: 'Pacifico', cursive;
}
.single_widget_info p {
  margin-top: 40px;
}
.single_widget_info p span {
  display: block;
}
.single_widget_info p span.phone_email {
  margin-top: 15px;
}
.footer_socail_icon {
  margin-top: 90px;
}
.footer_socail_icon a {
  padding: 5px;
}
.footer_socail_icon a i {
  font-size: 25px;
  color: #e17147;
}

.single_widget_info p span.date_day {
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 600;
}
.footer {
  background: #000;
}
.footer .copyright p {
  padding: 25px;
  color: #fff;
}
.footer .copyright p i {
  padding: 0px 10px;
  color: #ff0000;
}
.footer .copyright p a {
  padding: 0px 10px;
  color: green;
}

/* scrollup */

.scroll-up {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  opacity: 0.3;
  position: fixed;
  bottom: 20px;
  right: 25px;
  color: #fff;
  cursor: pointer;
  background-color: #000;
  z-index: 1000;
  transition: opacity 0.5s, background-color 0.5s;
  -moz-transition: opacity 0.5s, background-color 0.5s;
  -webkit-transition: opacity 0.5s, background-color 0.5s;
}

.scroll-up:hover {
  background: #e17147;
  opacity: 1;
}

.scroll-up i {
  font-size: 13px;
  position: absolute;
  opacity: 1;
  color: #fff;
  left: 50%;
  top: 50%;
  margin-top: -7px;
  margin-left: -6px;
  text-decoration: none;
}

/* Hide short text by default (resolution > 1200px)*/
/* #boisson ul li {
  background: url(../../../public/assets/images/vin.png) no-repeat left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#viandebrochette {
  background: url(../../../public/assets/images/newsfour.jpg) right center
    no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}

#fastfood {
  background: url(../../../public/assets/images/newsfive.jpg) left center
    no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}

#dessert {
  background: url(../../../public/assets/images/newsix.jpg) right center
    no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
} */

.short-text {
  display: none;
}
.short-resto-name {
  display: none;
}

/* .short-product-name {
  display: inline-block;
} */

/* When resolution <= 1200px, hide full text and show short text*/
@media (max-width: 1200px) {
  /* For portfolio */
  /* .short-product-name {
        display: none;
      } */
  .single_portfolio_text:hover .portfolio_images_overlay {
    top: 7px;
    left: 5px;
  }

  .portfolio_images_overlay h6 {
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    line-height: 24px;
  }

  .portfolio_images_overlay p.product_price {
    font-size: 15px;
    color: #fff;
    line-height: 0px;
  }

  .portfolio_images_overlay {
    width: 94%;
    height: 93%;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    margin: 0 auto;
    top: -100%;
    left: 14px;
    position: absolute;
    transition: 0.6s;
  }
  /* #END for portfolio */
  .short-text {
    display: inline-block;
  }
  .full-text {
    display: none;
  }
  .full-resto-name {
    display: none;
  }
  .short-resto-name {
    display: inline-block;
  }
  .short-resto-name {
    font-size: 40px;
    font-family: 'Pacifico', cursive;
    color: #fff;
    line-height: 6rem;
    margin: 0px 0px 60px 0px;
  }
}
