/* When resolution > 1200px, hide full text and show short text*/
.great-product-price {
  display: inline-block;
}

.short-product-price {
  display: none;
}

/* When resolution <= 1200px, hide full text and show short text*/
@media (max-width: 1200px) {
  .great-product-price {
    display: none;
  }

  .short-product-price {
    display: inline-block;
    color: white;
  }

  .short_product_name_price {
    position: relative;
    bottom: 18px;
  }
}
