#ourPakegCommandLink {
  text-align: center;
  position: relative;
  top: 20px;
}

#boisson ul li {
  background: url(../../../../../public/assets/images/vin.png) no-repeat left
    center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#viandebrochette {
  background: url(../../../../../public/assets/images/newsfour.jpg) right center
    no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}

#fastfood {
  background: url(../../../../../public/assets/images/newsfive.jpg) left center
    no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}

#dessert {
  background: url(../../../../../public/assets/images/newsix.jpg) right center
    no-repeat;
  padding: 30px;
  overflow: hidden;
  margin-top: 60px;
}

/* food icone */
#jusDeFruits {
  background: url(../../../../../public/assets/images/pkjus.png) no-repeat left
    center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#yaourt {
  background: url(../../../../../public/assets/images/pkyaourt.png) no-repeat
    left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#saladeVerte ul li {
  background: url(../../../../../public/assets/images/pksaladeverte.png)
    no-repeat left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#brochetteDeBoeuf {
  background: url(../../../../../public/assets/images/pkbrochettedeboeuf.png)
    no-repeat left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#brochetteDeSaucisse {
  background: url(../../../../../public/assets/images/pkbrochettedesauucisse.png)
    no-repeat left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#poulet,
#pintade,
#pouletYassa {
  background: url(../../../../../public/assets/images/pkpoulet.png) no-repeat
    left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#aileDePoulet {
  background: url(../../../../../public/assets/images/pkailedepoulet.png)
    no-repeat left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#tilapia {
  background: url(../../../../../public/assets/images/pktilapia.png) no-repeat
    left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#chawarma {
  background: url(../../../../../public/assets/images/pkchawarma.png) no-repeat
    left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#doubleBurger {
  background: url(../../../../../public/assets/images/pkdoubleburger.png)
    no-repeat left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#pizza {
  background: url(../../../../../public/assets/images/pkpizza.png) no-repeat
    left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#sandwichPoulet,
#sandwichViande,
#sandwichVegetarienne {
  background: url(../../../../../public/assets/images/pksandwich.png) no-repeat
    left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#spaghettiBlanc,
#spaghettiEcreme {
  background: url(../../../../../public/assets/images/pkspaghettiblanc.png)
    no-repeat left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#spaghettiBolognaise {
  background: url(../../../../../public/assets/images/pkspaghettibolognaise.png)
    no-repeat left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#rizBlanc {
  background: url(../../../../../public/assets/images/pkrizblanc.png) no-repeat
    left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

#rizCantonais {
  background: url(../../../../../public/assets/images/pkrizcantonais.png)
    no-repeat left center;
  padding: 15px 0px 15px 60px;
  margin-top: 5px;
}

@media (max-width: 1200px) {
  #ourPakegCommandLink {
    text-align: center;
  }
}
