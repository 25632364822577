#openHours {
  position: relative;
  bottom: 43px;
}

@media (max-width: 1200px) {
  /* .footer_widget {
    height: 850px;
  }

  #openHours {
    position: relative;
    bottom: 203px;
  } */

  .form-control {
    border: 1px solid rgba(0, 0, 0, 0.08);
    font-size: 16px;
    height: 34px;
    max-width: 100%;
    background: #323232;
  }

  #formid {
    text-align: center;
  }

  #greatDivFormid {
    position: relative;
    bottom: 30px;
  }
}
